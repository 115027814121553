const tokens = JSON.parse(localStorage.getItem('token'))
// **  Initial State
const initialState = {
  userData: {},
  role:'logged_out',
  notApproved: true,
  ...tokens,
  apiLoading: false
  //subscriptionDocuments: []
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        //userData: action.data,
        access_token: action.access_token,
        refresh_token: action.refresh_token,
        id_token: action.id_token,
        role:action.role
        //notApproved:action.notApproved
        // [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case 'LOGOUT':
      const obj = { ...action, access_token: null, refresh_token: null, role:'logged_out' }
      return { ...state, userData: {}, ...obj }
    case 'SET_USER_INFO':
      return {
        ...state,
        userData: action.userData
      }
    case 'SET_API_LOADING':
      return {
        ...state,
        apiLoading: action.value
      }
    default:
      return state
  }
}

export default authReducer
