const initialState = {
    groups: [],
    selectedOption: 'smsOption1',
    sendingOptions: [],
    sendingPurpose: 'NO_IYS',
    numberList: '',
    name: '',
    selectedGroup:null,
    message:'',
    senderIDs:[],
    selectedSenderID:null,
    is_flash:false,
    sendLater: {
        date:'',
        time:''
    },
    expire: {
        date:'',
        time:''
    },
    cron: '',
    batch: {

    },
    preview: {

    },
    previewOpen: false,
    contactList: [],
    checkedMessage:null,
    contactBlacklist: [],
    countryCode:"",
    excellNumberList: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_GROUPS':
            return {
                ...state,
                groups: action.groups
            }
        case 'SET_CHECKED_MESSAGE':
            return {
                ...state,
                checkedMessage: action.checkedMessage
            }  
        case 'SET_SELECTED_OPTION':
            return {
                ...state,
                selectedOption: action.selectedOption
            }
        case 'SET_COUNTRY_CODE':
            return {
                ...state,
                countryCode: action.countryCode
            }
        case 'SET_SELECTED_GROUP':
            return {
                ...state,
                selectedGroup: action.selectedGroup
            }
        case 'SET_SELECTED_OPTIONS':
            return {
                ...state,
                sendingOptions: action.sendingOptions
            }
        case 'SET_SENDING_PURPOSE':
            return {
                ...state,
                sendingPurpose: action.sendingPurpose
            }
        case 'SET_NUMBER_LIST':
            return {
                ...state,
                numberList:action.numberList
            }
        case 'SET_MESSAGE':
            return {
                ...state,
                message:action.message
            }
        case 'SET_NAME':
            return {
                ...state,
                name:action.name
            }
        case 'SET_SENDER_IDS_SEND_SMS':
            return {
                ...state,
                senderIDs:action.senderIDs
            }
        case 'SET_SELECTED_SENDER_ID':
            return {
                ...state,
                selectedSenderID:action.selectedSenderID
            }
        case 'SET_SEND_LATER':
            return {
                ...state,
                sendLater:{
                    date: action.date,
                    time: action.time
                }
            }
        case 'SET_CRON_PROPS':
            return {
                ...state,
                cron: action.cron
            }
        case 'SET_EXPIRE_DATE':
            return {
                ...state,
                expire:{
                    date: action.date,
                    time: action.time
                }
            }
        case 'SET_BATCH_PROPS':
            return {
                ...state,
                batch:{
                    batchSize: action.size,
                    interval: action.interval
                }
            }
        case 'SET_MESSAGE_PREVIEW':
            return {
                ...state,
                preview: action.preview,
                previewOpen: true
            }
        case 'SET_PREVIEW_OPEN':
            return {
                ...state,
                previewOpen: action.val
            }
        case 'SET_CONTACT_LIST_SEND_SMS':
            return {
                ...state,
                contactList: action.contactList
            }
        case 'FLUSH_SEND_SMS':
            return initialState
        case 'SET_UTF_16':
            return {
                ...state,
                utf_16: action.utf_16
            }

        case 'SET_FLASH_SMS':
            return {
                ...state,
                is_flash: action.is_flash
            }
        case 'SET_INTERNATIONAL':
            return {
                ...state,
                is_international: action.is_international
            }
        case 'SET_FOR_RESEND':
            return {
                ...state,
                ...action.state
            }
        case 'SET_CONTACT_BLACKLIST':
            return {
                ...state,
                contactBlacklist: action.contactBlacklist
            }
        case 'SET_NUMBER_LIST_FROM_EXCELL':
            return {
                ...state,
                excellNumberList: action.excellNumberList
            }
        default:
            return state
    }
}

export default authReducer
